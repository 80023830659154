import { PMPCategoryListViewProps } from 'lib/themes/themes';

import { CategoryCard } from './CategoryCard/CategoryCard';

const PMPCategoryListView = ({ categories }: PMPCategoryListViewProps) => {
  return (
    <ul className="base-topCategory__list">
      {categories.map((category, idx) => (
        <li key={idx} className="base-topCategory__list__item">
          <CategoryCard category={category} />
        </li>
      ))}
    </ul>
  );
};

export default PMPCategoryListView;
