import * as React from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import { ProductCollectionPage } from 'models/settings';
import { getImageUrl } from 'lib/util/imageUrl';
import { encodeCategoryNameForURI } from 'lib/util/encodeCategoryNameForURI';
import { appendQueryString } from 'lib/util/appendQueryString';

import styles from './CategoryCard.module.css';
import { useQueryString } from 'hooks/useQueryString';

type Props = {
  category: ProductCollectionPage;
};

export const CategoryCard = ({ category }: Props) => {
  const queryString = useQueryString();

  return (
    <Link
      href={appendQueryString(`/top/${encodeCategoryNameForURI(category.name)}`, queryString)}
      className={styles['c-pmp-card-category']}
    >
      <div className={styles['c-pmp-card-category__pic']}>
        {category.thumbnail_image_url && (
          <Image
            layout="fill"
            src={category.thumbnail_image_url}
            loader={({ src, width }) => getImageUrl(src, width)}
            alt="banner"
          />
        )}
      </div>
      <div className={styles['c-pmp-card-category__body']}>
        <h3 className={clsx(styles['c-pmp-card-category__body__ttl'], 'maincolor-txt')}>
          {category.display_name || category.name}
        </h3>
        <p className={styles['c-pmp-card-category__body__description']}>{category.description}</p>
      </div>
    </Link>
  );
};
